@import "styles/all";

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.period {
  display: flex;
  gap: desktop-responsive(8px);

  .periodText {
    @include font(18px, 400);

    color: $grey;
  }

  .periodDate {
    @include font(18px, 500);
  }
}

.filterBtn {
  @include font(16px, 400);

  all: unset;

  display: flex;
  gap: 5px;

  color: $grey !important;

  background-color: transparent !important;
}