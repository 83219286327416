@import "styles/all";

.userInfo {
  div:has(img) {
    width: desktop-responsive(88px) !important;
    height: desktop-responsive(88px) !important;
    margin-inline-end: desktop-responsive(32px) !important;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 100px;

  :global {
    .ant-form-item-label {
    text-align: start;  
    label {
        font-size: desktop-responsive(16px);
      }
    }
  }
}

.formGroup {
  width: 100%;
  padding-inline: desktop-responsive(40px);
  :global {
    .ant-form-item {
      width: desktop-responsive(655px) !important;
    }
  }
}

.formItemsectionWithButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.formBtnGroup {
  position: fixed;
  bottom: 0;
  inset-inline-start: 0;

  display: flex;
  gap: desktop-responsive(24px);
  justify-content: flex-end;

  width: 100%;
  padding: desktop-responsive(20px) desktop-responsive(80px);

  background: $light;
  border-top: 1px solid #d9d9d9;
  box-shadow: 0px 4px 24px 0px rgba(242, 242, 242, 0.2);

  button {
    justify-content: center;
    min-width: desktop-responsive(175px);
  }
}
