@import "styles/all";

.container {
    min-height: 100vh;

    header {
        .title {
            @include font(28px, 500);

            color: $dark;
        }
    }
}