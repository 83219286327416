@import "styles/all";

.container {
  display: flex;
  height: 100vh;
}

.formSection,
.logoSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.formSection {
  width: 55%;
  padding-inline: desktop-responsive(80px);
  .formContainer {
    display: flex;
    flex-direction: column;
    width: desktop-responsive(615px);
    margin-inline: auto;
  }
}

.logoSection {
  align-items: center;
  width: 45%;
  background-color: $lightGreyBackground;
  border-inline-start: 1px solid $lightGrey;
}

.logoImage {
  width: 264px;
  height: 264px;
}
