@import "styles/all";

.container {
  width: 100%;
  padding-block: desktop-responsive(24px);
  padding-inline: desktop-responsive(24px);

  .dateStyle {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 10px;

    border: 1px solid #d9d9d9;
    border-radius: 12px;
  }
  .to_time label {
    visibility: hidden !important;
    // background-color: black;
  }
}
