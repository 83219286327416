@import "styles/all";

.container {
    display: flex;
    align-items: center;

    .avatar {
        width: desktop-responsive(58px);
        height: desktop-responsive(58px);
        margin-inline-end: desktop-responsive(10px);
        /* Add spacing between avatar and name */

        background-color: #e3332d;
        border-radius: 50%;

        img {
            width: 100%;
            /* Adjust the width as needed */
            height: 100%;
            /* Adjust the height as needed */
        }
    }

    .memberName {
        @include font(24px, 500);

        display: block;
        color: $dark;
    }

    .memberId {
        @include font(16px, 500);

        color: $dark;
        opacity: 0.64;
    }
}

.tableCol {
    .avatar {
        width: desktop-responsive(48px);
        height: desktop-responsive(48px);
    }

    .memberName,
    .memberId {
        @include font(14px, 500);
    }
}