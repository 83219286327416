@import "styles/all";

.cardTitle {
  display: flex;
  justify-content: space-between;

  margin-bottom: desktop-responsive(20px);
  padding-block: desktop-responsive(24px);
  padding-inline: desktop-responsive(32px);

  font-size: desktop-responsive(24px);
  font-weight: 500;
  line-height: desktop-responsive(24px);
  text-transform: capitalize;

  border-bottom: 1px solid #d9d9d9;
}
.dataContainer {
  margin-bottom: desktop-responsive(20px);
  padding-inline-start: desktop-responsive(32px);
}
.label {
  font-size: desktop-responsive(16px);
  font-weight: 400;
  text-transform: capitalize;
  opacity: 0.72;
}
.value {
  font-size: desktop-responsive(16px);
  font-weight: 500;
  font-style: normal;
  line-height: desktop-responsive(16px); /* 100% */
  text-transform: capitalize;
}
