@import "styles/all";

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.secondarySidebar {
  display: flex;
  flex: 0 0 desktop-responsive(224px);
  flex-direction: column;
  gap: 14px;

  padding-top: 44px;
  padding-inline: desktop-responsive(20px);

  background: rgba(227, 51, 45, 0.02);

  > * {
    padding-block: 10px;
    padding-inline: desktop-responsive(20px);
  }

  > span {
    @include font(16px, 500);

    padding-top: 0;
    padding-inline-start: 0;
    color: $dark;
    opacity: 0.64;
  }
}

.navLink {
  @include font(16px, 400);

  color: $dark;
  text-transform: capitalize;

  opacity: 0.8;
  border-radius: 8px;

  transition: all 200ms ease-in-out;

  &:global {
    &.active {
      color: $primary;
      opacity: 1;
      background: rgba(227, 51, 45, 0.05);
    }
  }
}

.content {
  overflow: hidden;
  flex-grow: 1;

  min-height: calc(100vh - 104px);
  margin-top: 44px;
  margin-inline: desktop-responsive(40px);
}
