@import "styles/all";

.locationContainer {
  tbody tr td:first-child > div {
    justify-content: flex-start !important;
  }
  tbody tr td:nth-child(2) > div {
    text-align: center;
    // width: 200px;
  }
  tbody tr td:nth-child(2) > div > span {
    // max-width: 150px;
    white-space: normal;
  }
  thead tr th:first-child > span {
    text-align: start;
  }
}
