@import "styles/all";

.fullscreen {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
}

.lds-ring {
  position: relative;
  display: inline-block;
  width: desktop-responsive(20px);
  height: desktop-responsive(20px);
}
.lds-ring div {
  position: absolute;

  display: block;

  box-sizing: border-box;
  width: desktop-responsive(16px);
  height: desktop-responsive(16px);
  margin: 2px;

  border: 2px solid #fff;
  border-color: #fff transparent transparent transparent;
  border-radius: 50%;

  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
