@import "styles/all";

.infoCard {
  cursor: pointer;

  display: flex;
  flex: 0 0 calc(50% - desktop-responsive(27px));
  gap: desktop-responsive(16px);
  align-items: flex-start;
  justify-content: space-between;

  max-width: calc(50% - desktop-responsive(27px));
  padding: desktop-responsive(32px);

  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 12px;

  > div > span {
    @include font(20px, 500);

    color: #000;
    text-transform: capitalize;
  }
}

.cardContainers {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: desktop-responsive(27px);
}

.statusBadge {
  width: unset;
  height: 36px;
  padding-inline: desktop-responsive(16px);
  font-size: desktop-responsive(16px);
}
