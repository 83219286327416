@import "styles/all";

.container :global {
  width: 100%;
  .ant-select {
    width: 100% !important;
    min-height: desktop-responsive(52px);
    * {
      @include font(16px, 600);

      border-radius: 12px;
    }
    .ant-select-selector,
    .ant-select-item {
      padding: desktop-responsive(10px) desktop-responsive(24px);
    }
  }
}
