@import "styles/all";

.container {
  .headerBtn {
    display: inline-flex;
    margin-inline-start: 16px;
    padding: 10px !important;
    border-radius: 50% !important;
  }
  .bold {
    @include font(28px, 500);

    margin-inline-start: desktop-responsive(8px);
    color: $dark;
  }
  .export {
    padding-inline: 4rem !important;
  }
}
.filterBtn {
  @include font(16px, 400);

  all: unset;

  display: flex;
  gap: 5px;

  color: $grey !important;

  background-color: transparent !important;
}
