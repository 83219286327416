@import "styles/all";

.container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.vSepLine{
    width: 1px;
    height: 40px;
    background: rgba(206, 206, 206, 0.25);
}

.menuBtn {
    all: unset;
    cursor: pointer;
}
  
.actionBtn {
    all: unset;
  
    cursor: pointer;
  
    margin-inline-start: 16px;
    padding-inline-start: 16px;
  
    border-inline-start: 1px solid #cecece40;
}

.noSpacing{
    margin-inline-start: 0;
    padding-inline-start: 0;
    border-inline-start: 0px solid #cecece40;
}

.dangerMenuBtn{
    color: $danger;
}