@import "styles/all";

.btnGroups {
  display: flex;
  gap: desktop-responsive(16px);
  align-items: center;
}

.headerBtn {
  padding: 10px !important;
  border-radius: 50% !important;
}

.statusBadgeContanier {
  @include font(16px, 400);

  width: desktop-responsive(136px);
}

.tableContainer {
  tbody tr td:nth-child(2) > div {
    justify-content: flex-start !important;
  }

  thead tr th:nth-child(2) > span {
    text-align: start;
  }
}
