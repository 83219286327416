.view_profile {
  all: unset;
  cursor: pointer;
}

.memberActionBtn {
  all: unset;

  cursor: pointer;

  margin-inline-start: 16px;
  padding-inline-start: 16px;

  border-inline-start: 1px solid #cecece40;
}

.statusStyle {
  width: 100px;
  white-space: initial;
  padding-block: 5px;
  padding-inline: 20px;

  color: #dc3545;
  text-align: center;

  background-color: #dc35451a;
  border-radius: 12px;
}

.status-OUT {
  color: #fea100;
  background-color: #fea1001a;
}

.status-In {
  color: #fea100;
  background-color: #fea1001a;
}

.status-OFF {
  color: #1a9946;
  background-color: #1a99461a;
}
.status-Public_Holiday {
  color: #1a9946;
  background-color: #1a99461a;
}

.status-ok {
  color: #1a9946;
  background-color: #1a99461a;
}

.statusContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
