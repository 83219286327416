@import "styles/all";

.container {
  padding-bottom: desktop-responsive(40px);
  padding-inline: desktop-responsive(40px);
  font-size: desktop-responsive(16px);
}

.memberInfoID {
  display: block;
  font-size: 14px;
  opacity: 0.64;
}
.filterBtn {
  @include font(16px, 400);

  all: unset;

  display: flex;
  gap: 5px;

  color: $grey !important;

  background-color: transparent !important;
}
