@import "styles/all";

.container {
  width: 100%;

  .memberContent {
    width: 100%;
    padding: desktop-responsive(32px) desktop-responsive(40px);

    .memberInfo {
      display: flex;
      align-items: center;

      .avatar {
        width: desktop-responsive(58px);
        height: desktop-responsive(58px);
        margin-inline-end: desktop-responsive(10px);
        /* Add spacing between avatar and name */

        background-color: #e3332d;
        border-radius: 50%;

        img {
          width: 100%;
          /* Adjust the width as needed */
          height: 100%;
          /* Adjust the height as needed */
        }
      }

      .memberName {
        @include font(16px, 500);

        display: block;
        color: $dark;
      }

      .memberUpdatedDate {
        @include font(14px, 400);

        color: $dark;
        opacity: 0.64;
      }
    }
  }

  .keyValueView {
    & span {
      @include font(16px, 500);
    }
  }

  .buttonsGroup {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: desktop-responsive(32px) desktop-responsive(40px);

    button {
      display: flex;
      justify-content: center;
      margin-bottom: desktop-responsive(16px);
    }
  }
  .statusBadge {
    @include font(16px, 500);

    width: 100%;
    height: desktop-responsive(40px);
    margin-top: desktop-responsive(30px);
    padding-block: desktop-responsive(12px);

    text-transform: capitalize;

    border-radius: desktop-responsive(6px);
  }
}
