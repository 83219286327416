@import "styles/all";

.formSection,
.logoSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.title {
  @include font(40px, 600);

  color: $primary;
}

.secondaryTitle {
  @include font(18px, 400);

  margin-top: desktop-responsive(24px);
  margin-bottom: desktop-responsive(56px);
  color: $secondaryDarkGrey;
}

.form {
  .submitBtn {
    justify-content: center;
    width: 100%;
    height: desktop-responsive(56px);
    margin-top: desktop-responsive(80px);
  }
}

.forgotPassword {
  @include font(16px, 500);

  margin-top: desktop-responsive(32px);
  color: $dark;
  text-align: center;
}
