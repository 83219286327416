@import "styles/all";

.modal {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  border: none;
}

.modalBox {
  overflow: hidden;
  background-color: $light;
  border-radius: 16px;
}

.small {
  width: desktop-responsive(775px);
}

.large {
  width: desktop-responsive(1190px);
}

.modalContainer {
  display: flex;
  padding: desktop-responsive(24px);
  padding-bottom: 0;
}

.body {
  padding: 0;
}

.start {
  justify-content: start;
}

.center {
  justify-content: center;
}

.end {
  justify-content: end;
}

.header {
  border-bottom: 1px solid $lightGrey;
}

.headerTitle {
  display: flex;
  align-items: center;
  width: 100%;
}

.footer {
  justify-content: end;
  border-top: 1px solid $lightGrey;
}