@import "styles/all";

.container {
  display: flex;
  gap: 20px;
  align-items: center;

  width: 100%;
  height: 48px;
  margin-bottom: 20px;
  padding-inline: 30px;

  background-color: $lightPrimary;
  border-radius: 15px;
}
