@import "styles/all";

.container {
  display: flex;

  padding: desktop-responsive(12px);

  background: $light;
  border: 1px solid $lightGrey;
  border-radius: desktop-responsive(12px);
  & > * {
    flex: 1;
    margin-inline-end: desktop-responsive(12px);
    border-inline-end: 1px solid $lightGrey;
  }
  :last-child {
    margin-inline-end: unset;
    border-inline-end: unset;
  }
}
