@import "styles/all";

.container {
    width: 100%;
    padding-block: desktop-responsive(24px);
    padding-inline: desktop-responsive(24px);

    .memberContent {
        margin-bottom: desktop-responsive(16px);
        padding-bottom: desktop-responsive(16px);
    }

    .label {
        display: block;
        margin-block-end: desktop-responsive(12px);
    }
}
