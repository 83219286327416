@import "styles/all";
@import "~bootstrap/scss/bootstrap.scss";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: "Inter", sans-serif !important;
}

* {
  font-family: "Inter", sans-serif !important;
}

.ant-form-item-explain {
  margin-bottom: 24px;
}

.ant-dropdown-menu {
  li {
    padding: desktop-responsive(16px) !important;
    border-radius: unset !important;
  }

  li:not(:last-child) {
    border-bottom: 1px solid $grey;
  }
}

.ant-picker-range-arrow {
  display: none;
}

.ant-picker-dropdown .ant-picker-panel-container {
  box-shadow: none;
  transition: none;
}

.ant-picker-dropdown .ant-picker-cell .ant-picker-cell-inner,
.ant-picker-dropdown td {
  min-width: 32px !important;
  height: 32px !important;
  line-height: 32px !important;
}

.ant-picker-dropdown .ant-picker-cell::before,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range-hover::before {
  height: 32px !important;
}

.antdTabs.ant-tabs .ant-tabs-ink-bar {
  display: none !important;
}

.antdTabs.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  text-align: center !important;
  background-color: #e3332d0d;
}

.antdTabs.ant-tabs .ant-tabs-tab-btn {
  padding: 11px 42px;

  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;

  border-radius: 15px;
}

.antdTabs.ant-tabs>.ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list {
  background-color: #e3332d05;
}

.ant-tabs .ant-tabs-tabpane {
  padding-inline: desktop-responsive(24px);
}

.ant-modal-title {
  @include font(24px, 500);

  text-align: center;
}

.pointer {
  cursor: pointer;
}

.tabContainer-tab {
  border-radius: 8px !important;
}

.ant-form-item-label {
  text-align: start !important;
}

.ant-upload-list-item-container,.ant-upload.ant-upload-select {
  width: max-content !important;
  height: max-content !important;
  margin-bottom: 0 !important;
}