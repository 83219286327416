@import "styles/all";

.container {
  position: relative;

  overflow: hidden;
  display: flex;
  justify-content: space-between;

  width: 100%;
  margin-block: 1rem;
  padding: 8px 8px 8px 30px;

  border-radius: 6px;

  .startBorder {
    position: absolute;
    inset-inline-start: 0;
    width: 20px;
    height: 100%;
  }

  .button {
    height: 36px;
    padding: 12px 12px 18px !important;

    color: #cecece !important;

    background-color: #ffffff !important;
    border-color: #cecece !important;
  }

  .infoTxt {
    color: $info;
  }
  .successTxt {
    color: $success;
  }
}

.success {
  background: #1a994626; // Green
}
.absent {
  background: #ee9fa5; // RED
}
.permission {
  background: #fea10014; // Orange
}
.conflict {
  background: #fea10014; // Orange
}
.off {
  background: #1a994626; // Green
}
.publicHoliday {
  background: #d9d9d9;
}
// background: #f9f9f9; // grey

.successBorder {
  background-color: #1a9946;
}
.absentBorder {
  background-color: #dc3545;
}
.permissionBorder {
  background-color: #fea100;
}
.conflictBorder {
  background-color: #fea100;
}
.offBorder {
  background-color: #1a9946;

  // background-color: #d9d9d9;
  // background-color: rgb(39, 39, 39);
}
.publicHolidayBorder {
  background: #7e7e7e;
}
