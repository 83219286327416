@import "styles/all";

.description {
  overflow: hidden;
  display: -webkit-box;

  width: 175px;

  text-overflow: ellipsis;
  white-space: wrap;

  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.locationContainer {
  tbody tr td:first-child > div {
    justify-content: flex-start !important;
  }

  thead tr th:first-child > span {
    text-align: start;
  }
}
