@import "styles/all";

.container {
  display: flex;
  align-items: center;
  width: fit-content;
  padding-inline-end: 16px;

  .memberImage img {
    width: 50px;
    height: 50px;
    background-color: red;
    border-radius: 50%;
  }

  .memberContent {
    padding-inline-start: desktop-responsive(10px);
    padding-inline-end: desktop-responsive(16px);
  }
}
