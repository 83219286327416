@import "styles/all";

header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 40px;
}

.title {
  @include font(28px, 500);
}
