@import "styles/all";

.container :global {
  width: 100%;
  .ant-select {
    width: 100% !important;
    height: 52px;
    * {
      @include font(16px, 600);

      border-radius: 12px;
    }
    .ant-select-selector,
    .ant-select-item {
      padding: desktop-responsive(17px) desktop-responsive(24px);
      text-transform: capitalize;
    }
  }
  .ant-select-multiple {
    .ant-select-selector {
      padding-block: 0;
      padding-inline: desktop-responsive(17px) desktop-responsive(24px);
      text-transform: capitalize;
    }
  }
}
