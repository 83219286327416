@import "styles/all";

.container {
    @include font(16px, 400);

    color: $dark !important;
    border-color: $lightGrey !important;

    &:hover {
        color: $primary !important;
        border-color: $primary !important;

        svg {
            color: $primary !important;
        }
    }
}

.optionBtn {
    all: unset;
}