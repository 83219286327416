@import "styles/all";

.container {
    :global{
        .ant-tabs-tab.ant-tabs-tab-disabled{
            cursor: default;
            color: $dark;
        }
    }
}
