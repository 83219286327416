@import "styles/all";

.container {
  width: 100%;
  padding-block: desktop-responsive(24px);
  padding-inline: desktop-responsive(24px);
  .bold {
    @include font(28px, 500);

    margin-inline-start: desktop-responsive(8px);
    color: $dark;
  }
  .header {
    @include font(20px, 500);

    margin-inline-start: desktop-responsive(8px);
    color: $dark;
  }
  .memberImage img {
    width: 50px;
    height: 50px;
    background-color: red;
    border-radius: 50%;
    margin-inline-end: 16px;
  }

  .weekday {
    @include font(22px, 600);

    margin-inline-start: desktop-responsive(8px);
    margin-block: 16px;
    color: $dark;
  }
}
