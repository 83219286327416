@import "styles/all";

.tableContainer {
  overflow: auto;

  width: 100%;

  background-color: $light;
  border: 1px solid $lightGrey;
  border-radius: 15px;
}
.container {
  width: 100%;
}

.searchAndButton {
  display: flex;
  gap: desktop-responsive(20px);
  justify-content: center;

  margin-block: desktop-responsive(20px);
  margin-inline: auto;
}
.search {
  width: 85%;
}
.btn {
  display: flex;
  gap: desktop-responsive(16px);
  align-items: center;
}

table {
  width: 100%;
  & > * {
    @include font(16px, 400);
    & th {
      font-weight: normal;
    }
  }
}

tr,
th,
td {
  padding: desktop-responsive(24px) desktop-responsive(24px);
  white-space: nowrap;
}

tr {
  th,
  td {
    border: 1px solid $lightGrey;
  }
}

tr {
  > :first-child,
  > :last-child {
    position: sticky;
    z-index: 10;
    background-color: $light;
  }
  > :first-child {
    inset-inline-start: 0;
  }
  > :last-child {
    inset-inline-end: 0;
  }
  > :first-child::after,
  > :last-child::before {
    content: "";

    position: absolute;
    z-index: 2;
    top: 0;

    width: 1px; /* Adjust to match border thickness */
    height: 100%;

    background-color: $lightGrey;
  }
  > :first-child::after {
    inset-inline-end: -1px;
  }
  > :last-child::before {
    inset-inline-start: -1px;
  }
}

.headers {
  @include font(16px, 500);
}

.centeredHeader {
  display: block;
  width: 100%;
  text-align: center;
}
