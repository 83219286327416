@import "styles/all";
.approvalStatus {
  padding: 5px 30px;
  border-radius: 10px;

  margin-inline-start: desktop-responsive(50px);
}

.memberActionBtn {
  all: unset;

  cursor: pointer;

  margin-inline-start: 16px;
  padding-inline-start: 16px;

  border-inline-start: 1px solid #cecece40;
}

.view_profile {
  all: unset;
  cursor: pointer;
}

.statusBadge {
  @include font(16px, 500);

  width: desktop-responsive(112px);
  height: desktop-responsive(32px);
  padding-block: desktop-responsive(12px);

  text-transform: capitalize;

  border-radius: desktop-responsive(12px);
}
