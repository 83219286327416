@import "styles/all";

.subtitle {
  @include font(18px, 400);

  display: block;
  margin-top: desktop-responsive(16px);
  color: #121212a3;

  .bold {
    @include font(18px, 500);

    margin-inline-start: desktop-responsive(8px);
    color: $dark;
  }
}
