@import "styles/all";

.container {
  width: 100%;

  .contentSpace {
    padding: desktop-responsive(12px) desktop-responsive(40px);
  }

  .contentContainer {
    display: flex;
    justify-content: center;
  }

  .containerButtonsGroup {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: desktop-responsive(32px) desktop-responsive(40px);
    padding-bottom: 0;

    .buttonsGroup {
      display: flex;
      flex-direction: column;
      width: 100%;

      button {
        display: flex;
        justify-content: center;
        margin-bottom: desktop-responsive(16px);
      }
    }

    .backButton {
      height: 22px !important;
      padding: 0px !important;

      color: $primary !important;

      background-color: transparent !important;
      border: none !important;
      border-bottom: 1px solid $primary !important;
      border-radius: 0px !important;
    }
  }
}
