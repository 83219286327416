.container {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 32px;
}

.collapseHeader {
  display: flex;
  gap: 20px;
  align-items: center;
}

.collapseHeaderTitle {
  display: flex;
  align-items: center;
  gap: 8px;
}

.balanceName {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
}

.collapseContent {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
