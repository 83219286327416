@import "styles/all";

.container {
}
.memberActionBtn {
  all: unset;
  cursor: pointer;
}
.disabled {
  cursor: not-allowed !important;
}
li:has(span > button.disabled) {
  cursor: not-allowed !important;
  background-color: $lightGrey;
}
li:has(span > button.disabled):hover {
  cursor: not-allowed !important;
  background-color: $lightGrey !important;
}
