@import "styles/all";

.bold {
  @include font(28px, 500);

  margin-inline-start: desktop-responsive(8px);
  color: $dark;
}
.filterBtn {
  @include font(16px, 400);

  all: unset;

  display: flex;
  gap: 5px;

  color: $grey !important;

  background-color: transparent !important;
}
