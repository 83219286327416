@import "styles/all";

.container {
  padding-bottom: desktop-responsive(40px);
  padding-inline: desktop-responsive(40px);
}

.title {
  @include font(28px, 500);

  text-transform: capitalize;
}

.links {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 40px;

  .clickableCard {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-block: 16px;
    padding-inline: desktop-responsive(32px);

    color: $dark;

    background: #fff;
    border: 1px solid #f0f0f0;
    border-radius: 6px;
  }

  .clickableCard span{
    @include font(20px, 500);
    
    text-transform: capitalize;
  }

  .clickableCard .secondaryText{
    @include font(16px, 400);

    opacity: 0.8;
  }
}
