@import "styles/all";

.bold {
  @include font(28px, 500);

  margin-inline-start: desktop-responsive(8px);
  color: $dark;
}
.memberImage img {
  width: 50px;
  height: 50px;
  background-color: red;
  border-radius: 50%;
  margin-inline-end: 16px;
}

.weekday {
  @include font(22px, 600);

  margin-inline-start: desktop-responsive(8px);
  margin-block: 16px;
  color: $dark;
}
