@import "styles/all";

.container {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;

  font-size: 16px;
}

.containerReversed {
  display: flex;
  flex-direction: row-reverse !important;
  gap: 10px;
  align-items: center;
}
