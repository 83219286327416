@import "styles/all";

.customBtn {
    @include font(16px, 700);

    display: flex;
    flex-direction: row;
    gap:12px;
    align-items: center;

    height: 44px;
    padding: 8px 20px !important;

    text-transform: capitalize;

    border-radius: 12px !important;

    &:hover{
        filter: brightness(1.1);
    }
}
