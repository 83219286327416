@import "styles/all";

.container {
  width: 100%;
  padding-block: 20px;
  padding-inline: 40px;
  border-radius: 16px;
}
.textContainer {
  margin-block: auto;
  margin-inline-start: desktop-responsive(20px);
}
.name {
  font-family: Inter;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  line-height: 24px;
  text-transform: capitalize;
}
.titleIdContainer {
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 16px;
  color: var(--121212, #121212);
  text-transform: capitalize;

  opacity: 0.64;
}
.content {
  font-weight: 500;
}
.dash {
  margin-inline: desktop-responsive(8px);
}

.Details {
  display: flex;
  justify-content: space-between;
  margin-block: 10px 30px;
}

.avatar {
  width: desktop-responsive(48px);
  height: desktop-responsive(48px);
  margin: auto;
  /* Add spacing between avatar and name */

  background-color: #e3332d;
  border-radius: 50%;

  img {
    width: 100%;
    /* Adjust the width as needed */
    height: 100%;
    /* Adjust the height as needed */
  }
}
.bold {
  @include font(16px, 500);
  margin-inline-start: desktop-responsive(4px);
  color: $success;
}
.error {
  color: $danger;
}
