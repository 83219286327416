@import "styles/all";

.uploadContainer {
  width: 100%;

  .uploadButton {
    cursor: pointer;

    width: 100%;
    padding-block: desktop-responsive(80px);

    text-align: center;

    background-color: #fafafa;
    border: 2px dashed #d9d9d9;
    border-radius: 24px;

    .chooseFileTxt {
      color: $primary;
    }
  }

  .listContainer {
    overflow-y: auto;
    max-height: 120px;

    .fileListRow {
      display: flex;
      justify-content: space-between;

      margin: 1rem;
      padding: 1rem 1.5rem;

      border: 1px solid $primary;
      border-radius: 12px;

      .fileIcon {
        padding: 8px;
        border: 1px solid #d9d9d9;
        border-radius: 12px;
      }

      .fileData {
        display: flex;
        flex-direction: column;
        align-items: self-start;
        margin-inline-start: 1rem;

        .greyColor {
          color: $modalBackDrop;
        }
      }
    }

    .deleteIcon {
      font-size: 24px;
      color: $modalBackDrop;
    }
  }
}

.listContainer::-webkit-scrollbar {
  width: 6px;
}

.listContainer::-webkit-scrollbar-track {
    border-radius: 12px;
  -webkit-box-shadow: inset 0 0 6px var(--color-lightSecondary);
}

.listContainer::-webkit-scrollbar-thumb {
  background: var(--color-secondary);
    border-radius: 12px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.listContainer .css-1mzmax3-MuiButtonBase-root-MuiButton-root:hover {
  background-color: var(--color-primary) !important;
}
