$desktop-design-viewport: 1440px;
$mobile-design-viewport: 375px;

/* Colors */
$body-bg: #fff;
$primary: #e1241e;
$lightPrimary: #e3332d0d;
$secondary: #fdebea;
$info: #121212a3;
$dark: #121212;
$light: #ffffff;
$success: #1a9946;
$lightSuccess: #1a99461a;
$warning: #fea100;
$lightWarning: #fea1001a;
$grey: #494949;
$lightGrey: #cecece80;
$danger: #dc3545;
$fail: #fea100;
$lightGreen: #1a9946;
$modalBackDrop: #12121280;
$lightDangerBackground: #e3332d0a;
$lightDangerBorder: #e3332d80;
$lightGreyBackground: #fcfcfc;
$secondaryDarkGrey: #a0a0a0;
