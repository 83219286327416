@import "styles/all";

.navbar {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 88px;
  padding: 10px 20px;

  color: #000;

  background-color: #fff;
  border-bottom: solid 1px #cecece;
}

/* Style the logo */
.logo img {
  width: 116px;
  /* Adjust the width as needed */
  height: auto;
}

/* Style the user info section */
.user-info {
  display: flex;
  align-items: center;
}

/* Style the user's avatar */
.avatar {
  width: 48px;
  height: 48px;
  margin-right: 10px;
  /* Add spacing between avatar and name */

  background-color: $primary;
  border-radius: 50%;

  img {
    width: 100%;
    /* Adjust the width as needed */
    height: 100%;
    /* Adjust the height as needed */
  }
}

.notification {
  width: 28px;
  height: 28px;
  margin-right: 3rem;
  /* Add spacing between avatar and name */
  cursor: pointer;
  border-radius: 50%;

  img {
    width: 100%;
    /* Adjust the width as needed */
    height: 100%;
    /* Adjust the height as needed */
  }
}

/* Style the user's name and dropdown */
.user-dropdown {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;

  span {
    padding-inline-end: 12px;
  }
}

/* Style the dropdown content (hidden by default) */
.dropdown-content {
  position: absolute;
  z-index: 1;

  display: none;

  min-width: 150px;

  background-color: #333;
}

/* Style the dropdown links */
.dropdown-content a {
  display: block;
  padding: 10px;
  color: white;
  text-decoration: none;
}

/* Change link color on hover */
.dropdown-content a:hover {
  background-color: #555;
}

/* Show the dropdown content on hover */
.user-dropdown:hover .dropdown-content {
  display: block;
}

a {
  text-decoration: none;
}

.logoutButton {
  all: unset;
  color: $primary;
}

.gg-chevron-down {
  position: relative;
  transform: scale(var(--ggs, 1));

  display: block;

  box-sizing: border-box;
  width: 22px;
  height: 22px;

  color: #cecece;

  border: 2px solid transparent;
  border-radius: 100px;
}

.gg-chevron-down::after {
  content: "";

  position: absolute;
  top: 2px;
  left: 4px;
  transform: rotate(45deg);

  display: block;

  box-sizing: border-box;
  width: 10px;
  height: 10px;

  border-right: 2px solid;
  border-bottom: 2px solid;
}
