@import "styles/all";

.container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
        @include font(28px, 500);

        color: $dark;
    }

    .headerBtn {
        display: inline-flex;
        margin-inline-start: 16px;
        padding: 10px !important;
        border-radius: 50% !important;
    }

    .periodText{
        @include font(18px, 500);

        opacity: 0.64;
    }
    .periodDate{
        @include font(18px, 500);
    }
}