@import "styles/all";

.container {
  display: flex;
  gap: 10px;
}
.insideBox :global .ant-checkbox-inner{
  width: 24px !important;
  height: 24px !important;
}
