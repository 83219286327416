.view_profile {
  all: unset;
  cursor: pointer;
}

.memberActionBtn {
  all: unset;

  cursor: pointer;

  margin-inline-start: 16px;
  padding-inline-start: 16px;

  border-inline-start: 1px solid #cecece40;
}

.statusStyle {
  width: 30px;
  height: 30px;
  padding-block: 5px;
  padding-inline: 5px;

  text-align: center;

  background-color: #4949491a;
  border-radius: 12px;
}

.status-OUT {
  color: #fea100;
  background-color: #fea1001a;
}

.status-In {
  color: #1a9946;
  background-color: #1a99461a;
}

.statusContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
