@import "styles/all";

.datePicker {
  width: 100%;
  padding: desktop-responsive(17px) desktop-responsive(22px);
  border-radius: 12px;
}

.calender-icon {
  opacity: 0.8;
}
