@import "styles/all";

.container {
  display: flex;
  align-items: center;
  justify-content: center;

  width: desktop-responsive(40px);
  height: desktop-responsive(40px);

  font-size: desktop-responsive(20px);

  border-radius: desktop-responsive(8px);
}
