@import "styles/all";

$sidebar-width: 161px;
$collabsed-sidebar-width: 50px;

.sidenav {
  position: fixed;
  z-index: 1;
  top: 0;
  inset-inline-start: 0;

  display: flex;
  flex-direction: column;
  gap: 17px;
  align-items: center;

  width: $collabsed-sidebar-width;
  height: 100%;
  padding-top: calc(88px + 40px);

  background-color: #fff;
  border-inline-end: solid 1px #cecece;

  transition: all 300ms ease-in-out;

  &.open {
    width: $sidebar-width;
  }

  .activeVerticalBar {
    position: absolute;
    inset-inline-start: 0;

    width: 0px;
    height: 100%;

    background-color: $primary;
    border-radius: 0px 6px 6px 0px;

    transition: all 300ms ease-in-out;
  }

  a.active .activeVerticalBar {
    width: 4px;
  }

  a {
    position: relative;

    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    justify-content: center;

    width: 100%;
    padding: 11px 0px;

    color: $info;
    text-decoration: none;

    transition: all 300ms ease-in-out;

    &.active {
      color: $primary;
      background-color: $lightPrimary;

      svg {
        fill: $primary !important;
      }
    }

    span {
      @include font(16px, 400);

      overflow: hidden;

      width: 0px;
      max-height: 0px;

      text-align: center;
      letter-spacing: -0.352px;

      transition:
        width 300ms ease-in-out,
        max-height 300ms ease-in-out 0ms;

      &.open {
        width: $sidebar-width;
        max-height: 100px;
      }
    }
  }

  .toggle-btn {
    position: absolute;
    z-index: 20;
    right: 0;
    bottom: 4%;
    transform: translateX(50%) rotate(0);

    padding: 5px;

    background-color: $light;
    border: none;
    border-radius: 50%;

    transition: all 300ms ease-in-out;

    &.open {
      transform: translateX(50%) rotate(180deg);
    }
  }

  .navigationContent {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
  }
}

.sidelayoutContent {
  height: 100%;
  padding-top: calc(88px + 16px);
  background-color: #f9f9fa;
}
