@import "styles/all";

.container {
    .statusBadge {
        @include font(16px, 500);

        width: desktop-responsive(112px);
        height: desktop-responsive(32px);
        padding-block: desktop-responsive(12px);

        text-transform: capitalize;

        border-radius: desktop-responsive(12px);
    }
}