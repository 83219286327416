@import "styles/all";

.container {
  all: unset;

  cursor: pointer;

  display: flex;
  gap: desktop-responsive(16px);
  justify-content: space-between;

  width: -webkit-fill-available;
  height: auto !important;
  margin-bottom: desktop-responsive(32px);
  padding: desktop-responsive(32px) !important;

  background: #fff !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 12px;

  .content > span {
    @include font(16px, 600);

    display: block;
    color: #000;
    text-transform: capitalize;
    &:first-of-type {
      @include font(14px, 400);

      color: #121212;
      margin-bottom: 14px;
    }
    &:last-of-type {
      @include font(14px, 400);

      color: #121212;
    }
  }

  .statusBadge {
    @include font(16px, 500);

    width: desktop-responsive(120px);
    height: desktop-responsive(40px);
    padding-block: desktop-responsive(12px);

    text-transform: capitalize;

    border-radius: desktop-responsive(6px);
  }
}
